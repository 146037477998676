<template>
  <form
    ref="newsletterForm"
    class="mt-5 md:mt-8 ml-auto mr-auto"
    @submit.prevent="submit"
  >
    <div class="flex flex-col gap-2 items-start md:flex-row w-full h-full">
      <FormInput
        isBig
        v-model:modelValue="email"
        type="email"
        class="w-full flex-grow"
        :class="{
          'border-blue focus:shadow-input focus-within:ring-0':
            !true,
        }"
        required
        :placeholder="$t('Email')"
        name="email"
        autocomplete
        @update:modelValue="email = $event"
      />
      <CommonButton
        bg="bg-blue"
        text="text-white"
        size="xxlarge"
        class="mt-2 w-full md:w-auto"
        border="border-none"
        :disabled="!newsletterForm?.checkValidity()"
      >
        {{ submitLabel }}
      </CommonButton>
    </div>
    <div class="flex items-start justify-start mt-6 mx-1">
      <CommonPermissionBox
        isNewsletter
        :permissionGiven="marketingPermissionGiven"
        @update:permission="updateMarketingPermission"
        :required="!hasSubscription"
      />
    </div>
  </form>
</template>

<script setup lang="ts">
const nuxtApp = useNuxtApp()
const { nwCookie } = useNwCookie()
const gtm = useGtm()
const userStore = useUserStore()

const props = withDefaults(
  defineProps<{
    buttonText?: string
    eventLabel?: string
    newsletter?: string | number
  }>(),
  {
    eventLabel: 'FreeNewsletterSignup',
    newsletter: 1, // 1 = the free frontpage newsletter.
  }
)

const emit = defineEmits(['signed-up'])

const email = ref('')
const marketingPermissionGiven = ref(false)
const newsletterForm = ref<HTMLFormElement>()

const submitLabel = computed(() => props.buttonText || nuxtApp.$t('Signup4'))
const hasSubscription = computed(() => {
  return userStore?.user?.hasActiveSubscription
})

async function submit() {
  const { err } = await nuxtApp.$api.user.subscribeFreeNewsletter(
    email.value,
    FREE_NEWSLETTER_ID
  )

  if (err) {
    console.error(err.message, ': ', err.cause)
  } else {
    if (marketingPermissionGiven.value) {
      const { err } = await nuxtApp.$api.user.addMarketingPermission({
        email: email.value,
        permissionSource: 'Free Newsletter',
        permissionSourceUrl: window.location.href,
      })
      if (err) {
        console.error(err.message, ': ', err.cause)
      }
    }

    emit('signed-up')

    gtm?.trackEvent({
      event: 'formTracking',
      eventCategory: 'Form',
      eventAction: 'Form Completed',
      eventLabel: props.eventLabel,
    })

    nwCookie.value = true
  }
}

const updateMarketingPermission = (newValue: boolean) => {
  marketingPermissionGiven.value = newValue
}
</script>
